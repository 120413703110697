import React from 'react';

const IconBlog = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve">
    <g>
      <g>
        <g>
          <path
            d="M53.572,269.851C57.344,273.779,62.554,276,68,276s10.656-2.221,14.428-6.149l48-50C134.003,216.126,136,211.163,136,206
				V20c0-11.046-8.954-20-20-20H20C8.954,0,0,8.954,0,20v186c0,5.163,1.997,10.126,5.572,13.851L53.572,269.851z M40,40h56v157.954
				l-28,29.167l-28-29.167V40z"
          />
          <path d="M205.999,40H492c11.046,0,20-8.954,20-20s-8.954-20-20-20H205.999c-11.046,0-20,8.954-20,20S194.953,40,205.999,40z" />
          <path
            d="M492,472H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20
				C512,480.954,503.046,472,492,472z"
          />
          <path
            d="M492,354H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20
				C512,362.954,503.046,354,492,354z"
          />
          <path d="M492,118H205.999c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H492c11.046,0,20-8.954,20-20S503.046,118,492,118z" />
          <path
            d="M492,236H205.999c-11.046,0-20,8.954-20,20s8.954,20,20,20H492c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236
				z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default IconBlog;
